import {
  symOutlinedEdit,
  symOutlinedDelete,
  symOutlinedUpload,
  symOutlinedClose,
  symOutlinedCancel,
  symOutlinedFolderCopy,
  symOutlinedAccountTree,
  symOutlinedStorefront,
  symOutlinedHelp,
  symOutlinedKeyboardArrowLeft,
  symOutlinedKeyboardArrowRight,
  symOutlinedArrowForward,
  symOutlinedNotifications,
  symOutlinedNotificationsUnread,
  symOutlinedLogout,
  symOutlinedKey,
  symOutlinedSettings,
  symOutlinedContentCopy,
  symOutlinedPersonAdd,
  symOutlinedRestartAlt,
  symOutlinedWarning,
  symOutlinedPersonOff,
  symOutlinedPersonCheck,
  symOutlinedBlock,
  symOutlinedForwardToInbox,
  symOutlinedInbox,
  symOutlinedFilterAlt,
  symOutlinedFolderOpen,
  symOutlinedSearch,
  symOutlinedPerson,
  symOutlinedInfo,
  symOutlinedDescription,
  symOutlinedMenuBook,
  symOutlinedArrowBack,
  symOutlinedVerifiedUser,
  symOutlinedArticle,
  symOutlinedAccountCircle,
  symOutlinedCalendarToday,
  symOutlinedDeployedCode,
  symOutlinedCheckCircle,
  symOutlinedSchedule,
  symOutlinedInventory2,
  symOutlinedTimelapse,
  symOutlinedClockLoader40,
  symOutlinedPageInfo,
  symOutlinedBeenhere,
  symOutlinedShoppingmode,
  symOutlinedKeyboardArrowUp,
  symOutlinedKeyboardArrowDown,
  symOutlinedAcUnit,
  symOutlinedLock,
  symOutlinedSyncAlt,
  symOutlinedTask,
  symOutlinedChat,
  symOutlinedBarChart4Bars,
  symOutlinedVisibility,
  symOutlinedCheckBox,
  symOutlinedMoreVert,
  symOutlinedAddCircle,
  symOutlinedDownload,
  symOutlinedCheck,
  symOutlinedEventBusy,
  symOutlinedFormatH1,
  symOutlinedFormatBold,
  symOutlinedFormatItalic,
  symOutlinedAddLink,
  symOutlinedFormatListBulleted,
  symOutlinedFormatListNumbered,
  symOutlinedChecklist,
  symOutlinedFormatAlignCenter,
  symOutlinedFormatAlignRight,
  symOutlinedFormatAlignLeft,
  symOutlinedNorth,
  symOutlinedSouth,
  symOutlinedAssignment,
  symOutlinedKeyboardDoubleArrowLeft,
  symOutlinedKeyboardDoubleArrowRight,
  symOutlinedUndo,
  symOutlinedFilePresent,
  symOutlinedOpenInNew,
  symOutlinedRefresh,
  symOutlinedError,
  symOutlinedAdd,
  symOutlinedContactSupport,
  symOutlinedThumbUp,
  symOutlinedMenu,
} from '@quasar/extras/material-symbols-outlined';

export const editOutlined = symOutlinedEdit;
export const deleteOutlined = symOutlinedDelete;
export const uploadOutlined = symOutlinedUpload;
export const closeOutlined = symOutlinedClose;
export const cancelOutlined = symOutlinedCancel;
export const folderCopyOutlined = symOutlinedFolderCopy;
export const accountTreeOutlined = symOutlinedAccountTree;
export const storeOutlined = symOutlinedStorefront;
export const helpOutlined = symOutlinedHelp;
export const leftArrowOutlined = symOutlinedKeyboardArrowLeft;
export const rightArrowOutlined = symOutlinedKeyboardArrowRight;
export const forwardArrowOutlined = symOutlinedArrowForward;
export const notificationsOutlined = symOutlinedNotifications;
export const notificationsUnreadOutlined = symOutlinedNotificationsUnread;
export const logoutOutlined = symOutlinedLogout;
export const keyOutlined = symOutlinedKey;
export const settingsOutlined = symOutlinedSettings;
export const contentCopyOutlined = symOutlinedContentCopy;
export const personAddOutlined = symOutlinedPersonAdd;
export const restartAltOutlined = symOutlinedRestartAlt;
export const warningOutlined = symOutlinedWarning;
export const personOffOutlined = symOutlinedPersonOff;
export const personCheckOutlined = symOutlinedPersonCheck;
export const blockOutlined = symOutlinedBlock;
export const forwardToInboxOutlined = symOutlinedForwardToInbox;
export const inboxOutlined = symOutlinedInbox;
export const filterAltOutlined = symOutlinedFilterAlt;
export const folderOpenOutlined = symOutlinedFolderOpen;
export const searchOutlined = symOutlinedSearch;
export const personOutlined = symOutlinedPerson;
export const infoOutlined = symOutlinedInfo;
export const descriptionOutlined = symOutlinedDescription;
export const menuBookOutlined = symOutlinedMenuBook;
export const arrowBackOutlined = symOutlinedArrowBack;
export const verifiedUserOutlined = symOutlinedVerifiedUser;
export const articleOutlined = symOutlinedArticle;
export const accountCircleOutlined = symOutlinedAccountCircle;
export const calendarTodayOutlined = symOutlinedCalendarToday;
export const deployedCodeOutlined = symOutlinedDeployedCode;
export const checkCircleOutlined = symOutlinedCheckCircle;
export const scheduleOutlined = symOutlinedSchedule;
export const inventory2Outlined = symOutlinedInventory2;
export const clockLoader60Outlined = symOutlinedTimelapse;
export const clockLoader40Outlined = symOutlinedClockLoader40;
export const pageInfoOutlined = symOutlinedPageInfo;
export const beenhereOutlined = symOutlinedBeenhere;
export const shoppingmodeOutlined = symOutlinedShoppingmode;
export const keyboardArrowUpOutlined = symOutlinedKeyboardArrowUp;
export const keyboardArrowDownOutlined = symOutlinedKeyboardArrowDown;
export const acUnitOutlined = symOutlinedAcUnit;
export const lockOutlined = symOutlinedLock;
export const syncAltOutlined = symOutlinedSyncAlt;
export const taskOutlined = symOutlinedTask;
export const chatOutlined = symOutlinedChat;
export const barChartOutlined = symOutlinedBarChart4Bars;
export const visibilityOutlined = symOutlinedVisibility;
export const checkBoxOutlined = symOutlinedCheckBox;
export const moreVertOutlined = symOutlinedMoreVert;
export const addCircleOutlined = symOutlinedAddCircle;
export const downloadOutlined = symOutlinedDownload;
export const checkOutlined = symOutlinedCheck;
export const eventBusyOutlined = symOutlinedEventBusy;
export const formatH1Outlined = symOutlinedFormatH1;
export const formatBoldOutlined = symOutlinedFormatBold;
export const formatItalicOutlined = symOutlinedFormatItalic;
export const addLinkOutlined = symOutlinedAddLink;
export const formatListBulletedOutlined = symOutlinedFormatListBulleted;
export const formatListNumberedOutlined = symOutlinedFormatListNumbered;
export const checklistOutlined = symOutlinedChecklist;
export const formatAlignCenterOutlined = symOutlinedFormatAlignCenter;
export const formatAlignRightOutlined = symOutlinedFormatAlignRight;
export const formatAlignLeftOutlined = symOutlinedFormatAlignLeft;
export const northOutlined = symOutlinedNorth;
export const southOutlined = symOutlinedSouth;
export const assignmentOutlined = symOutlinedAssignment;
export const keyboardDoubleArrowLeftOutlined = symOutlinedKeyboardDoubleArrowLeft;
export const keyboardDoubleArrowRightOutlined = symOutlinedKeyboardDoubleArrowRight;
export const undoOutlined = symOutlinedUndo;
export const filePresentOutlined = symOutlinedFilePresent;
export const openInNewOutlined = symOutlinedOpenInNew;
export const refreshOutlined = symOutlinedRefresh;
export const errorOutlined = symOutlinedError;
export const addOutlined = symOutlinedAdd;
export const contactSupportOutlined = symOutlinedContactSupport;
export const thumbUpOutlined = symOutlinedThumbUp;
export const menuOutlined = symOutlinedMenu;
