/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreateUser } from '../model';
// @ts-ignore
import type { CreationFrameworkTemplate } from '../model';
// @ts-ignore
import type { CustomProjectPage } from '../model';
// @ts-ignore
import type { ErrorResponse } from '../model';
// @ts-ignore
import type { HTTPValidationError } from '../model';
// @ts-ignore
import type { InviteUser } from '../model';
// @ts-ignore
import type { Organization } from '../model';
// @ts-ignore
import type { OrganizationQuota } from '../model';
// @ts-ignore
import type { OrganizationRoles } from '../model';
// @ts-ignore
import type { OrganizationUpdate } from '../model';
// @ts-ignore
import type { OrganizationUser } from '../model';
// @ts-ignore
import type { OrganizationsMetricsPage } from '../model';
// @ts-ignore
import type { OrganizationsOverview } from '../model';
// @ts-ignore
import type { PageOrganization } from '../model';
// @ts-ignore
import type { ProjectType } from '../model';
// @ts-ignore
import type { Tags } from '../model';
// @ts-ignore
import type { UpdateUserOID } from '../model';
// @ts-ignore
import type { UserActivationStatus } from '../model';
// @ts-ignore
import type { UserOrgPage } from '../model';
/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an organization.  Args:     organization (schemas.OrganizationCreate): The organization to create.     db (Session): The database session.  Returns:     schemas.Organization: The created organization.
         * @summary Create Organization
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationApiOrganizationsPost: async (organizationUpdate: OrganizationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUpdate' is not null or undefined
            assertParamExists('createOrganizationApiOrganizationsPost', 'organizationUpdate', organizationUpdate)
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Organizations User Roles
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {OrganizationRoles} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete: async (userId: string, organizationId: string, role: OrganizationRoles, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete', 'organizationId', organizationId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete', 'role', role)
            const localVarPath = `/api/organizations/{organization_id}/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Templates Of Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete: async (frameworkCode: string, organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frameworkCode' is not null or undefined
            assertParamExists('deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete', 'frameworkCode', frameworkCode)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/templates/{framework_code}`
                .replace(`{${"framework_code"}}`, encodeURIComponent(String(frameworkCode)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Frameworks Of Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet: async (organizationId: string, projectType?: ProjectType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/templates`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (projectType !== undefined) {
                localVarQueryParameter['project_type'] = projectType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the organization quota. Args:     organization_id (schemas.UUID): The organization ID.     db (Session): The database session. Raises:     HTTPException: If the organization is not found. Returns (schemas.OrganizationQuota): The organization quota.
         * @summary Get Organization Quota
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/quota`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all organizations.  Args:     db (Session): The database session.  Returns:     Page[schemas.Organization]: The organizations.
         * @summary Get Organizations
         * @param {string | null} [name] The name filter.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsApiOrganizationsGet: async (name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get metrics for all organizations.  Args:     db: Database session     params: Pagination parameters     name: Optional name filter     sort_by: Optional field to sort by     descending: Sort direction  Returns:     Page of organization metrics
         * @summary Get Organizations Metrics
         * @param {string | null} [name] Filter by organization name
         * @param {string | null} [sortBy] Sort by a field
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {boolean} [defaultSort] If no sort_by is provided, default_sort True forces to keep the default (usually creationdate) sorting
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsMetricsApiV1OrganizationsMetricsGet: async (name?: string | null, sortBy?: string | null, descending?: boolean, defaultSort?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (defaultSort !== undefined) {
                localVarQueryParameter['default_sort'] = defaultSort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get overview metrics for all organizations.  Returns aggregated metrics across all organizations including: - Total number of organizations - Total number of organization projects - Total number of AI application projects - Total number of active users (excluding internal users)  Args:     db: Database session  Returns:     OrganizationsOverview: Overview metrics across all organizations
         * @summary Get Organizations Overview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsOverviewApiV1OrganizationsOverviewGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the projects of the organization.  Args:     organization (Orgnaization): The organization.     db (Session): Database session.     name (str, optional): Filter by project name. Defaults to None.     project_type (enums.ProjectType, optional): Filter by project type.     params (Params, optional): Pagination params. Defaults to Depends().     sort_by (str, optional): Sort by a field. Defaults to None.     descending (bool, optional): Sort order. If true, descending, otherwise         ascending. Defaults to True.  Returns:     Page[schemas.Project]: List of projects.
         * @summary Get Projects
         * @param {string} organizationId 
         * @param {string | null} [name] Filter by project name
         * @param {ProjectType} [projectType] 
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsApiOrganizationsOrganizationIdProjectsGet: async (organizationId: string, name?: string | null, projectType?: ProjectType, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getProjectsApiOrganizationsOrganizationIdProjectsGet', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/projects`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (projectType !== undefined) {
                localVarQueryParameter['project_type'] = projectType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tags
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsApiOrganizationsOrganizationIdTagsGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getTagsApiOrganizationsOrganizationIdTagsGet', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/tags`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users Of Organization
         * @param {string} organizationId 
         * @param {string | null} [name] The user name filter.
         * @param {string | null} [notInProject] Filter by users not in a project.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {boolean} [includeInactive] Filter by status. By default, inactive users will not be returned.
         * @param {string | null} [state] Filter by user current state.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet: async (organizationId: string, name?: string | null, notInProject?: string | null, sortBy?: string | null, descending?: boolean, includeInactive?: boolean, state?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/users`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (notInProject !== undefined) {
                localVarQueryParameter['not_in_project'] = notInProject;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['include_inactive'] = includeInactive;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Rganizations User Roles
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {Array<OrganizationRoles>} organizationRoles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch: async (userId: string, organizationId: string, organizationRoles: Array<OrganizationRoles>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch', 'organizationId', organizationId)
            // verify required parameter 'organizationRoles' is not null or undefined
            assertParamExists('patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch', 'organizationRoles', organizationRoles)
            const localVarPath = `/api/organizations/{organization_id}/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationRoles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Organizations User Roles
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {OrganizationRoles} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost: async (userId: string, organizationId: string, role: OrganizationRoles, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost', 'organizationId', organizationId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost', 'role', role)
            const localVarPath = `/api/organizations/{organization_id}/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post User Force
         * @param {string} organizationId 
         * @param {CreateUser} createUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserForceApiOrganizationsOrganizationIdUsersForcePost: async (organizationId: string, createUser: CreateUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('postUserForceApiOrganizationsOrganizationIdUsersForcePost', 'organizationId', organizationId)
            // verify required parameter 'createUser' is not null or undefined
            assertParamExists('postUserForceApiOrganizationsOrganizationIdUsersForcePost', 'createUser', createUser)
            const localVarPath = `/api/organizations/{organization_id}/users_force`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Users
         * @param {string} organizationId 
         * @param {InviteUser} inviteUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsersApiOrganizationsOrganizationIdUsersPost: async (organizationId: string, inviteUser: InviteUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('postUsersApiOrganizationsOrganizationIdUsersPost', 'organizationId', organizationId)
            // verify required parameter 'inviteUser' is not null or undefined
            assertParamExists('postUsersApiOrganizationsOrganizationIdUsersPost', 'inviteUser', inviteUser)
            const localVarPath = `/api/organizations/{organization_id}/users`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an organization.  Args:     organization_update (schemas.OrganizationUpdate): The update to do for the         organization.     organization (int): The organization to update.     db (Session): The database session.  Returns:     schemas.Organization: The updated organization.
         * @summary Update Organization
         * @param {string} organizationId 
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationApiOrganizationsOrganizationIdPatch: async (organizationId: string, organizationUpdate: OrganizationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganizationApiOrganizationsOrganizationIdPatch', 'organizationId', organizationId)
            // verify required parameter 'organizationUpdate' is not null or undefined
            assertParamExists('updateOrganizationApiOrganizationsOrganizationIdPatch', 'organizationUpdate', organizationUpdate)
            const localVarPath = `/api/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an user.  Args:     user_update (schemas.UserUpdate): The update to do for the user.     user (schemas.User): The user to update.     db (Session): The database session.  Returns:     schemas.User: The updated user.
         * @summary Update Organizations User
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UpdateUserOID} updateUserOID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch: async (organizationId: string, userId: string, updateUserOID: UpdateUserOID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch', 'userId', userId)
            // verify required parameter 'updateUserOID' is not null or undefined
            assertParamExists('updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch', 'updateUserOID', updateUserOID)
            const localVarPath = `/api/organizations/{organization_id}/users/{user_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserOID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Status
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UserActivationStatus} userActivationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch: async (organizationId: string, userId: string, userActivationStatus: UserActivationStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch', 'userId', userId)
            // verify required parameter 'userActivationStatus' is not null or undefined
            assertParamExists('updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch', 'userActivationStatus', userActivationStatus)
            const localVarPath = `/api/organizations/{organization_id}/users/{user_id}/status`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userActivationStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Templates To Organization
         * @param {string} organizationId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost: async (organizationId: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost', 'organizationId', organizationId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost', 'file', file)
            const localVarPath = `/api/organizations/{organization_id}/templates`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates an organization.  Args:     organization (schemas.OrganizationCreate): The organization to create.     db (Session): The database session.  Returns:     schemas.Organization: The created organization.
         * @summary Create Organization
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganizationApiOrganizationsPost(organizationUpdate: OrganizationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationApiOrganizationsPost(organizationUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.createOrganizationApiOrganizationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Organizations User Roles
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {OrganizationRoles} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete(userId: string, organizationId: string, role: OrganizationRoles, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete(userId, organizationId, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Templates Of Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(frameworkCode: string, organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(frameworkCode, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Frameworks Of Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(organizationId: string, projectType?: ProjectType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreationFrameworkTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(organizationId, projectType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the organization quota. Args:     organization_id (schemas.UUID): The organization ID.     db (Session): The database session. Raises:     HTTPException: If the organization is not found. Returns (schemas.OrganizationQuota): The organization quota.
         * @summary Get Organization Quota
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationQuota>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all organizations.  Args:     db (Session): The database session.  Returns:     Page[schemas.Organization]: The organizations.
         * @summary Get Organizations
         * @param {string | null} [name] The name filter.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsApiOrganizationsGet(name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOrganization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsApiOrganizationsGet(name, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationsApiOrganizationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get metrics for all organizations.  Args:     db: Database session     params: Pagination parameters     name: Optional name filter     sort_by: Optional field to sort by     descending: Sort direction  Returns:     Page of organization metrics
         * @summary Get Organizations Metrics
         * @param {string | null} [name] Filter by organization name
         * @param {string | null} [sortBy] Sort by a field
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {boolean} [defaultSort] If no sort_by is provided, default_sort True forces to keep the default (usually creationdate) sorting
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsMetricsApiV1OrganizationsMetricsGet(name?: string | null, sortBy?: string | null, descending?: boolean, defaultSort?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsMetricsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsMetricsApiV1OrganizationsMetricsGet(name, sortBy, descending, defaultSort, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationsMetricsApiV1OrganizationsMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get overview metrics for all organizations.  Returns aggregated metrics across all organizations including: - Total number of organizations - Total number of organization projects - Total number of AI application projects - Total number of active users (excluding internal users)  Args:     db: Database session  Returns:     OrganizationsOverview: Overview metrics across all organizations
         * @summary Get Organizations Overview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsOverviewApiV1OrganizationsOverviewGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsOverviewApiV1OrganizationsOverviewGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationsOverviewApiV1OrganizationsOverviewGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the projects of the organization.  Args:     organization (Orgnaization): The organization.     db (Session): Database session.     name (str, optional): Filter by project name. Defaults to None.     project_type (enums.ProjectType, optional): Filter by project type.     params (Params, optional): Pagination params. Defaults to Depends().     sort_by (str, optional): Sort by a field. Defaults to None.     descending (bool, optional): Sort order. If true, descending, otherwise         ascending. Defaults to True.  Returns:     Page[schemas.Project]: List of projects.
         * @summary Get Projects
         * @param {string} organizationId 
         * @param {string | null} [name] Filter by project name
         * @param {ProjectType} [projectType] 
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsApiOrganizationsOrganizationIdProjectsGet(organizationId: string, name?: string | null, projectType?: ProjectType, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsApiOrganizationsOrganizationIdProjectsGet(organizationId, name, projectType, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getProjectsApiOrganizationsOrganizationIdProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Tags
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagsApiOrganizationsOrganizationIdTagsGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tags>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagsApiOrganizationsOrganizationIdTagsGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getTagsApiOrganizationsOrganizationIdTagsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Users Of Organization
         * @param {string} organizationId 
         * @param {string | null} [name] The user name filter.
         * @param {string | null} [notInProject] Filter by users not in a project.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {boolean} [includeInactive] Filter by status. By default, inactive users will not be returned.
         * @param {string | null} [state] Filter by user current state.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(organizationId: string, name?: string | null, notInProject?: string | null, sortBy?: string | null, descending?: boolean, includeInactive?: boolean, state?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOrgPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(organizationId, name, notInProject, sortBy, descending, includeInactive, state, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch Rganizations User Roles
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {Array<OrganizationRoles>} organizationRoles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch(userId: string, organizationId: string, organizationRoles: Array<OrganizationRoles>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch(userId, organizationId, organizationRoles, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post Organizations User Roles
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {OrganizationRoles} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost(userId: string, organizationId: string, role: OrganizationRoles, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost(userId, organizationId, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post User Force
         * @param {string} organizationId 
         * @param {CreateUser} createUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserForceApiOrganizationsOrganizationIdUsersForcePost(organizationId: string, createUser: CreateUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserForceApiOrganizationsOrganizationIdUsersForcePost(organizationId, createUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.postUserForceApiOrganizationsOrganizationIdUsersForcePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post Users
         * @param {string} organizationId 
         * @param {InviteUser} inviteUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUsersApiOrganizationsOrganizationIdUsersPost(organizationId: string, inviteUser: InviteUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUsersApiOrganizationsOrganizationIdUsersPost(organizationId, inviteUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.postUsersApiOrganizationsOrganizationIdUsersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an organization.  Args:     organization_update (schemas.OrganizationUpdate): The update to do for the         organization.     organization (int): The organization to update.     db (Session): The database session.  Returns:     schemas.Organization: The updated organization.
         * @summary Update Organization
         * @param {string} organizationId 
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationApiOrganizationsOrganizationIdPatch(organizationId: string, organizationUpdate: OrganizationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationApiOrganizationsOrganizationIdPatch(organizationId, organizationUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.updateOrganizationApiOrganizationsOrganizationIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an user.  Args:     user_update (schemas.UserUpdate): The update to do for the user.     user (schemas.User): The user to update.     db (Session): The database session.  Returns:     schemas.User: The updated user.
         * @summary Update Organizations User
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UpdateUserOID} updateUserOID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch(organizationId: string, userId: string, updateUserOID: UpdateUserOID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch(organizationId, userId, updateUserOID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User Status
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UserActivationStatus} userActivationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(organizationId: string, userId: string, userActivationStatus: UserActivationStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(organizationId, userId, userActivationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Templates To Organization
         * @param {string} organizationId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(organizationId: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreationFrameworkTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(organizationId, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * Creates an organization.  Args:     organization (schemas.OrganizationCreate): The organization to create.     db (Session): The database session.  Returns:     schemas.Organization: The created organization.
         * @summary Create Organization
         * @param {OrganizationsApiCreateOrganizationApiOrganizationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationApiOrganizationsPost(requestParameters: OrganizationsApiCreateOrganizationApiOrganizationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Organization> {
            return localVarFp.createOrganizationApiOrganizationsPost(requestParameters.organizationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Organizations User Roles
         * @param {OrganizationsApiDeleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete(requestParameters: OrganizationsApiDeleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete(requestParameters.userId, requestParameters.organizationId, requestParameters.role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Templates Of Organization
         * @param {OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(requestParameters: OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Frameworks Of Organization
         * @param {OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(requestParameters: OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CreationFrameworkTemplate>> {
            return localVarFp.getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the organization quota. Args:     organization_id (schemas.UUID): The organization ID.     db (Session): The database session. Raises:     HTTPException: If the organization is not found. Returns (schemas.OrganizationQuota): The organization quota.
         * @summary Get Organization Quota
         * @param {OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(requestParameters: OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationQuota> {
            return localVarFp.getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all organizations.  Args:     db (Session): The database session.  Returns:     Page[schemas.Organization]: The organizations.
         * @summary Get Organizations
         * @param {OrganizationsApiGetOrganizationsApiOrganizationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsApiOrganizationsGet(requestParameters: OrganizationsApiGetOrganizationsApiOrganizationsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageOrganization> {
            return localVarFp.getOrganizationsApiOrganizationsGet(requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Get metrics for all organizations.  Args:     db: Database session     params: Pagination parameters     name: Optional name filter     sort_by: Optional field to sort by     descending: Sort direction  Returns:     Page of organization metrics
         * @summary Get Organizations Metrics
         * @param {OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsMetricsApiV1OrganizationsMetricsGet(requestParameters: OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationsMetricsPage> {
            return localVarFp.getOrganizationsMetricsApiV1OrganizationsMetricsGet(requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.defaultSort, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Get overview metrics for all organizations.  Returns aggregated metrics across all organizations including: - Total number of organizations - Total number of organization projects - Total number of AI application projects - Total number of active users (excluding internal users)  Args:     db: Database session  Returns:     OrganizationsOverview: Overview metrics across all organizations
         * @summary Get Organizations Overview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsOverviewApiV1OrganizationsOverviewGet(options?: RawAxiosRequestConfig): AxiosPromise<OrganizationsOverview> {
            return localVarFp.getOrganizationsOverviewApiV1OrganizationsOverviewGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the projects of the organization.  Args:     organization (Orgnaization): The organization.     db (Session): Database session.     name (str, optional): Filter by project name. Defaults to None.     project_type (enums.ProjectType, optional): Filter by project type.     params (Params, optional): Pagination params. Defaults to Depends().     sort_by (str, optional): Sort by a field. Defaults to None.     descending (bool, optional): Sort order. If true, descending, otherwise         ascending. Defaults to True.  Returns:     Page[schemas.Project]: List of projects.
         * @summary Get Projects
         * @param {OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsApiOrganizationsOrganizationIdProjectsGet(requestParameters: OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomProjectPage> {
            return localVarFp.getProjectsApiOrganizationsOrganizationIdProjectsGet(requestParameters.organizationId, requestParameters.name, requestParameters.projectType, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tags
         * @param {OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsApiOrganizationsOrganizationIdTagsGet(requestParameters: OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tags> {
            return localVarFp.getTagsApiOrganizationsOrganizationIdTagsGet(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users Of Organization
         * @param {OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(requestParameters: OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserOrgPage> {
            return localVarFp.getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(requestParameters.organizationId, requestParameters.name, requestParameters.notInProject, requestParameters.sortBy, requestParameters.descending, requestParameters.includeInactive, requestParameters.state, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Rganizations User Roles
         * @param {OrganizationsApiPatchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch(requestParameters: OrganizationsApiPatchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch(requestParameters.userId, requestParameters.organizationId, requestParameters.organizationRoles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Organizations User Roles
         * @param {OrganizationsApiPostOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost(requestParameters: OrganizationsApiPostOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost(requestParameters.userId, requestParameters.organizationId, requestParameters.role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post User Force
         * @param {OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserForceApiOrganizationsOrganizationIdUsersForcePost(requestParameters: OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.postUserForceApiOrganizationsOrganizationIdUsersForcePost(requestParameters.organizationId, requestParameters.createUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Users
         * @param {OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsersApiOrganizationsOrganizationIdUsersPost(requestParameters: OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.postUsersApiOrganizationsOrganizationIdUsersPost(requestParameters.organizationId, requestParameters.inviteUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an organization.  Args:     organization_update (schemas.OrganizationUpdate): The update to do for the         organization.     organization (int): The organization to update.     db (Session): The database session.  Returns:     schemas.Organization: The updated organization.
         * @summary Update Organization
         * @param {OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationApiOrganizationsOrganizationIdPatch(requestParameters: OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Organization> {
            return localVarFp.updateOrganizationApiOrganizationsOrganizationIdPatch(requestParameters.organizationId, requestParameters.organizationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an user.  Args:     user_update (schemas.UserUpdate): The update to do for the user.     user (schemas.User): The user to update.     db (Session): The database session.  Returns:     schemas.User: The updated user.
         * @summary Update Organizations User
         * @param {OrganizationsApiUpdateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch(requestParameters: OrganizationsApiUpdateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch(requestParameters.organizationId, requestParameters.userId, requestParameters.updateUserOID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Status
         * @param {OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(requestParameters: OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(requestParameters.organizationId, requestParameters.userId, requestParameters.userActivationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Templates To Organization
         * @param {OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(requestParameters: OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CreationFrameworkTemplate>> {
            return localVarFp.uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(requestParameters.organizationId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createOrganizationApiOrganizationsPost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiCreateOrganizationApiOrganizationsPostRequest
 */
export interface OrganizationsApiCreateOrganizationApiOrganizationsPostRequest {
    /**
     * 
     * @type {OrganizationUpdate}
     * @memberof OrganizationsApiCreateOrganizationApiOrganizationsPost
     */
    readonly organizationUpdate: OrganizationUpdate
}

/**
 * Request parameters for deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiDeleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDeleteRequest
 */
export interface OrganizationsApiDeleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiDeleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiDeleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete
     */
    readonly organizationId: string

    /**
     * 
     * @type {OrganizationRoles}
     * @memberof OrganizationsApiDeleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete
     */
    readonly role: OrganizationRoles
}

/**
 * Request parameters for deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest
 */
export interface OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete
     */
    readonly frameworkCode: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete
     */
    readonly organizationId: string
}

/**
 * Request parameters for getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest
 */
export interface OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet
     */
    readonly organizationId: string

    /**
     * 
     * @type {ProjectType}
     * @memberof OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet
     */
    readonly projectType?: ProjectType
}

/**
 * Request parameters for getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest
 */
export interface OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet
     */
    readonly organizationId: string
}

/**
 * Request parameters for getOrganizationsApiOrganizationsGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetOrganizationsApiOrganizationsGetRequest
 */
export interface OrganizationsApiGetOrganizationsApiOrganizationsGetRequest {
    /**
     * The name filter.
     * @type {string}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly name?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getOrganizationsMetricsApiV1OrganizationsMetricsGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGetRequest
 */
export interface OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGetRequest {
    /**
     * Filter by organization name
     * @type {string}
     * @memberof OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGet
     */
    readonly name?: string | null

    /**
     * Sort by a field
     * @type {string}
     * @memberof OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGet
     */
    readonly descending?: boolean

    /**
     * If no sort_by is provided, default_sort True forces to keep the default (usually creationdate) sorting
     * @type {boolean}
     * @memberof OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGet
     */
    readonly defaultSort?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectsApiOrganizationsOrganizationIdProjectsGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest
 */
export interface OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly organizationId: string

    /**
     * Filter by project name
     * @type {string}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly name?: string | null

    /**
     * 
     * @type {ProjectType}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly projectType?: ProjectType

    /**
     * Sort by a field.
     * @type {string}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getTagsApiOrganizationsOrganizationIdTagsGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest
 */
export interface OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGet
     */
    readonly organizationId: string
}

/**
 * Request parameters for getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest
 */
export interface OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly organizationId: string

    /**
     * The user name filter.
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly name?: string | null

    /**
     * Filter by users not in a project.
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly notInProject?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly descending?: boolean

    /**
     * Filter by status. By default, inactive users will not be returned.
     * @type {boolean}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly includeInactive?: boolean

    /**
     * Filter by user current state.
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly state?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly size?: number
}

/**
 * Request parameters for patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiPatchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatchRequest
 */
export interface OrganizationsApiPatchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiPatchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiPatchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch
     */
    readonly organizationId: string

    /**
     * 
     * @type {Array<OrganizationRoles>}
     * @memberof OrganizationsApiPatchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch
     */
    readonly organizationRoles: Array<OrganizationRoles>
}

/**
 * Request parameters for postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiPostOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPostRequest
 */
export interface OrganizationsApiPostOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiPostOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiPostOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost
     */
    readonly organizationId: string

    /**
     * 
     * @type {OrganizationRoles}
     * @memberof OrganizationsApiPostOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost
     */
    readonly role: OrganizationRoles
}

/**
 * Request parameters for postUserForceApiOrganizationsOrganizationIdUsersForcePost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest
 */
export interface OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePost
     */
    readonly organizationId: string

    /**
     * 
     * @type {CreateUser}
     * @memberof OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePost
     */
    readonly createUser: CreateUser
}

/**
 * Request parameters for postUsersApiOrganizationsOrganizationIdUsersPost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest
 */
export interface OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPost
     */
    readonly organizationId: string

    /**
     * 
     * @type {InviteUser}
     * @memberof OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPost
     */
    readonly inviteUser: InviteUser
}

/**
 * Request parameters for updateOrganizationApiOrganizationsOrganizationIdPatch operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest
 */
export interface OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatch
     */
    readonly organizationId: string

    /**
     * 
     * @type {OrganizationUpdate}
     * @memberof OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatch
     */
    readonly organizationUpdate: OrganizationUpdate
}

/**
 * Request parameters for updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiUpdateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatchRequest
 */
export interface OrganizationsApiUpdateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateUserOID}
     * @memberof OrganizationsApiUpdateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch
     */
    readonly updateUserOID: UpdateUserOID
}

/**
 * Request parameters for updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest
 */
export interface OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch
     */
    readonly userId: string

    /**
     * 
     * @type {UserActivationStatus}
     * @memberof OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch
     */
    readonly userActivationStatus: UserActivationStatus
}

/**
 * Request parameters for uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest
 */
export interface OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost
     */
    readonly organizationId: string

    /**
     * 
     * @type {File}
     * @memberof OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost
     */
    readonly file: File
}

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * Creates an organization.  Args:     organization (schemas.OrganizationCreate): The organization to create.     db (Session): The database session.  Returns:     schemas.Organization: The created organization.
     * @summary Create Organization
     * @param {OrganizationsApiCreateOrganizationApiOrganizationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public createOrganizationApiOrganizationsPost(requestParameters: OrganizationsApiCreateOrganizationApiOrganizationsPostRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).createOrganizationApiOrganizationsPost(requestParameters.organizationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Organizations User Roles
     * @param {OrganizationsApiDeleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete(requestParameters: OrganizationsApiDeleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDeleteRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).deleteOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesDelete(requestParameters.userId, requestParameters.organizationId, requestParameters.role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Templates Of Organization
     * @param {OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(requestParameters: OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Frameworks Of Organization
     * @param {OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(requestParameters: OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the organization quota. Args:     organization_id (schemas.UUID): The organization ID.     db (Session): The database session. Raises:     HTTPException: If the organization is not found. Returns (schemas.OrganizationQuota): The organization quota.
     * @summary Get Organization Quota
     * @param {OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(requestParameters: OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all organizations.  Args:     db (Session): The database session.  Returns:     Page[schemas.Organization]: The organizations.
     * @summary Get Organizations
     * @param {OrganizationsApiGetOrganizationsApiOrganizationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationsApiOrganizationsGet(requestParameters: OrganizationsApiGetOrganizationsApiOrganizationsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationsApiOrganizationsGet(requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get metrics for all organizations.  Args:     db: Database session     params: Pagination parameters     name: Optional name filter     sort_by: Optional field to sort by     descending: Sort direction  Returns:     Page of organization metrics
     * @summary Get Organizations Metrics
     * @param {OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationsMetricsApiV1OrganizationsMetricsGet(requestParameters: OrganizationsApiGetOrganizationsMetricsApiV1OrganizationsMetricsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationsMetricsApiV1OrganizationsMetricsGet(requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.defaultSort, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get overview metrics for all organizations.  Returns aggregated metrics across all organizations including: - Total number of organizations - Total number of organization projects - Total number of AI application projects - Total number of active users (excluding internal users)  Args:     db: Database session  Returns:     OrganizationsOverview: Overview metrics across all organizations
     * @summary Get Organizations Overview
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationsOverviewApiV1OrganizationsOverviewGet(options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationsOverviewApiV1OrganizationsOverviewGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the projects of the organization.  Args:     organization (Orgnaization): The organization.     db (Session): Database session.     name (str, optional): Filter by project name. Defaults to None.     project_type (enums.ProjectType, optional): Filter by project type.     params (Params, optional): Pagination params. Defaults to Depends().     sort_by (str, optional): Sort by a field. Defaults to None.     descending (bool, optional): Sort order. If true, descending, otherwise         ascending. Defaults to True.  Returns:     Page[schemas.Project]: List of projects.
     * @summary Get Projects
     * @param {OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getProjectsApiOrganizationsOrganizationIdProjectsGet(requestParameters: OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getProjectsApiOrganizationsOrganizationIdProjectsGet(requestParameters.organizationId, requestParameters.name, requestParameters.projectType, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tags
     * @param {OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getTagsApiOrganizationsOrganizationIdTagsGet(requestParameters: OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getTagsApiOrganizationsOrganizationIdTagsGet(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users Of Organization
     * @param {OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(requestParameters: OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(requestParameters.organizationId, requestParameters.name, requestParameters.notInProject, requestParameters.sortBy, requestParameters.descending, requestParameters.includeInactive, requestParameters.state, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Rganizations User Roles
     * @param {OrganizationsApiPatchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch(requestParameters: OrganizationsApiPatchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatchRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).patchRganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPatch(requestParameters.userId, requestParameters.organizationId, requestParameters.organizationRoles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Organizations User Roles
     * @param {OrganizationsApiPostOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost(requestParameters: OrganizationsApiPostOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPostRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).postOrganizationsUserRolesApiOrganizationsOrganizationIdUsersUserIdRolesPost(requestParameters.userId, requestParameters.organizationId, requestParameters.role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post User Force
     * @param {OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public postUserForceApiOrganizationsOrganizationIdUsersForcePost(requestParameters: OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).postUserForceApiOrganizationsOrganizationIdUsersForcePost(requestParameters.organizationId, requestParameters.createUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Users
     * @param {OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public postUsersApiOrganizationsOrganizationIdUsersPost(requestParameters: OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).postUsersApiOrganizationsOrganizationIdUsersPost(requestParameters.organizationId, requestParameters.inviteUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an organization.  Args:     organization_update (schemas.OrganizationUpdate): The update to do for the         organization.     organization (int): The organization to update.     db (Session): The database session.  Returns:     schemas.Organization: The updated organization.
     * @summary Update Organization
     * @param {OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganizationApiOrganizationsOrganizationIdPatch(requestParameters: OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateOrganizationApiOrganizationsOrganizationIdPatch(requestParameters.organizationId, requestParameters.organizationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an user.  Args:     user_update (schemas.UserUpdate): The update to do for the user.     user (schemas.User): The user to update.     db (Session): The database session.  Returns:     schemas.User: The updated user.
     * @summary Update Organizations User
     * @param {OrganizationsApiUpdateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch(requestParameters: OrganizationsApiUpdateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatchRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateOrganizationsUserApiOrganizationsOrganizationIdUsersUserIdPatch(requestParameters.organizationId, requestParameters.userId, requestParameters.updateUserOID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Status
     * @param {OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(requestParameters: OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(requestParameters.organizationId, requestParameters.userId, requestParameters.userActivationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Templates To Organization
     * @param {OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(requestParameters: OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(requestParameters.organizationId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}

