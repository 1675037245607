import initApi from '@/client_heplers/utils.ts';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { UsersApi } from '@/client';

import { handleApiError } from '@/composables/ApiError.ts';
import type { AxiosError } from 'axios';
import type {
  ResponseGetUserApiUsersUserIdGet,
  UsersApiGetUserApiUsersUserIdGetRequest,
} from '@/client';

export const useUsersStore = defineStore('user', () => {
  const currentUser = ref<ResponseGetUserApiUsersUserIdGet | null>(null);

  const getUser = async (params: UsersApiGetUserApiUsersUserIdGetRequest) => {
    try {
      const usersApi = await initApi(UsersApi);

      const response = await usersApi?.getUserApiUsersUserIdGet(params);

      if (!response) return;
      currentUser.value = response.data;

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    getUser,
    currentUser,
  };
});
