import { useGlobalStore } from '@/stores/GlobalStore.ts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const decodeMessage = (error: any) => {
  const reader = new FileReader();

  reader.onload = function () {
    const result = reader.result;
    const store = useGlobalStore();

    if (typeof result === 'string') {
      const responseData = JSON.parse(result);

      store.$q.notify({
        message: responseData.detail,
        color: 'red',
      });
    } else if (result instanceof ArrayBuffer) {
      const textDecoder = new TextDecoder();
      const responseData = JSON.parse(textDecoder.decode(result));

      store.$q.notify({
        message: responseData.detail,
        color: 'red',
      });
    } else {
      console.error(
        'Error occurred while reading the Blob: Result is not a string or ArrayBuffer.',
      );
    }
  };

  reader.onerror = function () {
    console.error('Error occurred while reading the Blob.');
  };
  reader.readAsText(error.response.data);
};
