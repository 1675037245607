/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Roles = {
    Owner: 'owner',
    Reviewer: 'reviewer',
    Editor: 'editor',
    Auditor: 'auditor'
} as const;

export type Roles = typeof Roles[keyof typeof Roles];



