import router from '@/router';
import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { type Router } from 'vue-router';

import { handleError } from './handlers.ts';

function defineInterceptors(axios: AxiosInstance, router: Router, showNotifications = true) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;

      if (error.response) {
        return handleError(axios, router, originalConfig, error, showNotifications);
      }
      return Promise.reject(error);
    },
  );
}

export default defineInterceptors;

export function getHttpClient({ showNotifications = true }: { showNotifications?: boolean } = {}) {
  const httpClient = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
  });

  defineInterceptors(httpClient, router, showNotifications);

  return httpClient;
}
