/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ErrorResponse } from '../model';
// @ts-ignore
import type { FrameworkAggregator } from '../model';
// @ts-ignore
import type { FrameworkDetailed } from '../model';
// @ts-ignore
import type { FrameworkOverview } from '../model';
// @ts-ignore
import type { HTTPValidationError } from '../model';
// @ts-ignore
import type { ProjectType } from '../model';
/**
 * FrameworksApi - axios parameter creator
 * @export
 */
export const FrameworksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the framework with the given id. Args:     framework_id (UUID): The framework\'s id.  Returns:     framework: The framework with the given id.
         * @summary Get Framework
         * @param {string} frameworkId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFrameworkApiFrameworksFrameworkIdGet: async (frameworkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frameworkId' is not null or undefined
            assertParamExists('getFrameworkApiFrameworksFrameworkIdGet', 'frameworkId', frameworkId)
            const localVarPath = `/api/frameworks/{framework_id}`
                .replace(`{${"framework_id"}}`, encodeURIComponent(String(frameworkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Framework In Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet: async (frameworkCode: string, organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frameworkCode' is not null or undefined
            assertParamExists('getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet', 'frameworkCode', frameworkCode)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/frameworks/{framework_code}`
                .replace(`{${"framework_code"}}`, encodeURIComponent(String(frameworkCode)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Frameworks In Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet: async (organizationId: string, projectType?: ProjectType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/frameworks`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (projectType !== undefined) {
                localVarQueryParameter['project_type'] = projectType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrameworksApi - functional programming interface
 * @export
 */
export const FrameworksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrameworksApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the framework with the given id. Args:     framework_id (UUID): The framework\'s id.  Returns:     framework: The framework with the given id.
         * @summary Get Framework
         * @param {string} frameworkId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getFrameworkApiFrameworksFrameworkIdGet(frameworkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrameworkAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworkApiFrameworksFrameworkIdGet(frameworkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrameworksApi.getFrameworkApiFrameworksFrameworkIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Framework In Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(frameworkCode: string, organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrameworkDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(frameworkCode, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrameworksApi.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Frameworks In Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(organizationId: string, projectType?: ProjectType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FrameworkOverview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(organizationId, projectType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrameworksApi.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FrameworksApi - factory interface
 * @export
 */
export const FrameworksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrameworksApiFp(configuration)
    return {
        /**
         * Returns the framework with the given id. Args:     framework_id (UUID): The framework\'s id.  Returns:     framework: The framework with the given id.
         * @summary Get Framework
         * @param {FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFrameworkApiFrameworksFrameworkIdGet(requestParameters: FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FrameworkAggregator> {
            return localVarFp.getFrameworkApiFrameworksFrameworkIdGet(requestParameters.frameworkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Framework In Organization
         * @param {FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters: FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FrameworkDetailed> {
            return localVarFp.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Frameworks In Organization
         * @param {FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters: FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FrameworkOverview>> {
            return localVarFp.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getFrameworkApiFrameworksFrameworkIdGet operation in FrameworksApi.
 * @export
 * @interface FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest
 */
export interface FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FrameworksApiGetFrameworkApiFrameworksFrameworkIdGet
     */
    readonly frameworkId: string
}

/**
 * Request parameters for getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet operation in FrameworksApi.
 * @export
 * @interface FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest
 */
export interface FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet
     */
    readonly frameworkCode: string

    /**
     * 
     * @type {string}
     * @memberof FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet
     */
    readonly organizationId: string
}

/**
 * Request parameters for getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet operation in FrameworksApi.
 * @export
 * @interface FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest
 */
export interface FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet
     */
    readonly organizationId: string

    /**
     * 
     * @type {ProjectType}
     * @memberof FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet
     */
    readonly projectType?: ProjectType
}

/**
 * FrameworksApi - object-oriented interface
 * @export
 * @class FrameworksApi
 * @extends {BaseAPI}
 */
export class FrameworksApi extends BaseAPI {
    /**
     * Returns the framework with the given id. Args:     framework_id (UUID): The framework\'s id.  Returns:     framework: The framework with the given id.
     * @summary Get Framework
     * @param {FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FrameworksApi
     */
    public getFrameworkApiFrameworksFrameworkIdGet(requestParameters: FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest, options?: RawAxiosRequestConfig) {
        return FrameworksApiFp(this.configuration).getFrameworkApiFrameworksFrameworkIdGet(requestParameters.frameworkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Framework In Organization
     * @param {FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrameworksApi
     */
    public getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters: FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest, options?: RawAxiosRequestConfig) {
        return FrameworksApiFp(this.configuration).getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Frameworks In Organization
     * @param {FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrameworksApi
     */
    public getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters: FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest, options?: RawAxiosRequestConfig) {
        return FrameworksApiFp(this.configuration).getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(this.axios, this.basePath));
    }
}

