/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosInstance } from 'axios';
import { type Router } from 'vue-router';

import { backOff } from 'exponential-backoff';

import { decodeMessage } from './utils.ts';

import { useAuthStore } from '@/stores/AuthStore.ts';
import { useGlobalStore } from '@/stores/GlobalStore.ts';
import { useAuth } from '@/composables/Auth.ts';

const retryMap = new Map();

export const handle401Error = async (axios: AxiosInstance, originalConfig: any, error: any) => {
  const authStore = useAuthStore();

  const token = await authStore.getAccessTokenFromRefreshToken();
  if (!token) {
    const { logout } = useAuth();
    logout();

    return;
  }

  const originalRequest = error.config;
  originalRequest.headers['Authorization'] = `Bearer ${token}`;

  return axios(originalRequest);
};

export const handleError = async (
  axios: AxiosInstance,
  router: Router,
  originalConfig: any,
  error: any,
  showNotifications: boolean = true,
) => {
  const store = useGlobalStore();

  const message = error.response.data?.detail || error.response.statusText || 'An error occurred.';

  if (error.response.status >= 500) {
    try {
      await backOff(
        async () => {
          const response = await fetch(`${import.meta.env.VITE_API_URL}/api/health`);
          if (response.status !== 200) {
            throw new Error('Health check failed');
          }
        },
        {
          delayFirstAttempt: true,
          numOfAttempts: 4,
          startingDelay: 500,
          timeMultiple: 2.71,
        },
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      router.push('/service_down');
    }

    return Promise.reject(error);
  }

  const configId = originalConfig.url + (originalConfig.method || 'get');
  const hasRetried = retryMap.get(configId);

  if (error.response.status === 401 && !hasRetried) {
    retryMap.set(configId, true);

    return handle401Error(axios, originalConfig, error);
  } else if (error.response.status === 401 && hasRetried) {
    const { logout } = useAuth();
    retryMap.delete(configId);
    logout();

    return;
  }

  if (error.response.status === 404) {
    return Promise.reject(error);
  }

  // TODO: Remove this when stops handling errors from the interceptor.
  if (showNotifications) {
    if (typeof message === 'string') {
      store.$q.notify({
        message: message,
        color: 'red',
      });
    } else {
      decodeMessage(error);
    }
  }

  return Promise.reject(error);
};
