import { AxiosError } from 'axios';

export class ApiError extends AxiosError {
  constructor(message: string) {
    super(message);
    this.name = 'ApiError';
  }
}

export class BadRequestError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'BadRequestError';
  }
}

export class UnauthorizedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

export class ForbiddenError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'ForbiddenError';
  }
}

export class NotFoundError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

class NotAllowedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotAllowedError';
  }
}

class NotAcceptableError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotAcceptableError';
  }
}

class PreconditionFailedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'PreconditionFailedError';
  }
}

class UnsupportedMediaTypeError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'UnsupportedMediaTypeError';
  }
}

export class InternalServerError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'InternalServerError';
  }
}

export class NotImplementedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotImplementedError';
  }
}

export class ContentTooLargeError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'ContentTooLargeError';
  }
}

export class UnprocessableContentError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'UnprocessableContentError';
  }
}

export class ConnectionTimeoutError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'ConnectionTimeoutError';
  }
}

export class BadGatewayError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'BadGatewayError';
  }
}

export class ServiceUnavailableError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'ServiceUnavailableError';
  }
}

export class NetworkUnavailableError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NetworkUnavailableError';
  }
}

export class CanceledError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'CanceledError';
  }
}

interface ErrorData {
  detail: string | null;
}

export const handleApiError = (error: AxiosError) => {
  const errorMessage = error.message ? error.message.toLowerCase() : null;

  if (error.code === 'ECONNABORTED' || (errorMessage && errorMessage.includes('connection'))) {
    throw new ConnectionTimeoutError('Connection timeout');
  } else if (error.code === 'ENETWORK' || (errorMessage && errorMessage.includes('network'))) {
    // Network unavailable
    throw new NetworkUnavailableError('Network unavailable');
  } else if (errorMessage && errorMessage === 'canceled') {
    // Canceled request , don't throw error;
    throw new CanceledError('Canceled request');
  }

  if (!error.response) {
    throw new Error('An error occurred: ' + error.message);
  }

  const errorData: ErrorData = error.response.data as ErrorData;

  const errorDataMessage = errorData.detail || error.message;

  switch (error.response.status) {
    case 400:
      throw new BadRequestError(errorDataMessage);
    case 401:
      throw new UnauthorizedError(errorDataMessage);
    case 403:
      throw new ForbiddenError(errorDataMessage);
    case 404:
      throw new NotFoundError(errorDataMessage);
    case 405:
      throw new NotAllowedError(errorDataMessage);
    case 406:
      throw new NotAcceptableError(errorDataMessage);
    case 412:
      throw new PreconditionFailedError(errorDataMessage);
    case 413:
      throw new ContentTooLargeError(errorDataMessage);
    case 415:
      throw new UnsupportedMediaTypeError(errorDataMessage);
    case 422:
      throw new UnprocessableContentError(errorDataMessage);
    case 500:
      throw new InternalServerError(errorDataMessage);
    case 501:
      throw new NotImplementedError(errorDataMessage);
    case 502:
      throw new BadGatewayError(errorDataMessage);
    case 503:
      throw new ServiceUnavailableError(errorDataMessage);
    default:
      throw new Error('An error occurred: ' + errorMessage);
  }
};
