import { useGlobalStore } from '@/stores/GlobalStore.ts';
import DOMPurify from 'dompurify';
import { computed, ref } from 'vue';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import type { UserInviteResponse } from '@/client';

dayjs.extend(utc);
dayjs.extend(timezone);

export const MAX_NAME_SIZE = 50;
export const MAX_DESCRIPTION_SIZE = 250;
export const MAX_FILE_SIZE = 104857600; // 100MB
export const CURRENT_YEAR = computed(() => new Date().getFullYear());

export const CONCEPT = {
  RISK: 'Risk',
  CONTROL: 'Control',
  EVIDENCE: 'Evidence',
  ASSET: 'Asset',
} as const;

export const formatDate = (
  date: string,
  hoursMinutes: boolean = true,
  format: string = 'DD-MM-YYYY',
) => {
  const parsedDate = dayjs.utc(date).local();

  return hoursMinutes ? parsedDate.format(`${format} HH:mm`) : parsedDate.format(format);
};

export const blobImage = async (url: string) => {
  const res = await fetch(url);
  const blob = await res.blob();
  const imageBlob = new Blob([blob], { type: 'image/svg+xml' });
  return imageBlob;
};

interface RejectedEntry {
  file: File;
  failedPropValidation: string;
}

export const fileRejected = (rejectedEntries: RejectedEntry[]) => {
  const store = useGlobalStore();
  const rejectedItems = rejectedEntries;
  const overSizedItems = ref<RejectedEntry[]>([]);

  rejectedItems.forEach((item) => {
    if (item.failedPropValidation === 'max-file-size') {
      overSizedItems.value.push(item);
    }
  });

  const overSizedItemsNames = overSizedItems.value.map((item) => item.file.name).join(', ');

  if (overSizedItemsNames.length > 0) {
    store.$q.notify({
      color: 'red',
      textColor: 'white',
      icon: 'error',
      message: `Maximum file size is ${bytesToMb(MAX_FILE_SIZE)}MB. Failed to upload files: ${overSizedItemsNames}`,
    });
  }
};

export function bytesToMb(bytes: number): number {
  return Number((bytes / (1024 * 1024)).toFixed(0));
}

export function validateURL(url: string): boolean {
  if (!url) return true;

  try {
    // Sanitize the URL
    const sanitizedURL = DOMPurify.sanitize(url);

    // Parse with URL constructor
    const parsedURL = new URL(sanitizedURL);

    // Ensure it's http or https
    return parsedURL.protocol === 'http:' || parsedURL.protocol === 'https:';
  } catch {
    return false; // Invalid URL
  }
}

export function lastInvite(invites: UserInviteResponse[]) {
  const sortedInvites = invites.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  const lastInvite = sortedInvites[0];

  return lastInvite;
}

// Formatting

export function formatFileSize(input: string | number): string {
  let bytes: number;

  if (typeof input === 'string') {
    bytes = parseInt(input, 10);
  } else {
    bytes = input;
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

export function formatNumber(num: number) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return num.toString();
}

export const FORMAT_CONDITION = {
  GET_FIRST_LETTER_IN_UPPERCASE: 'getFirstLetterInUpperCase',
  FIRST_LETTER_TO_UPPERCASE: 'firstLetterToUpperCase',
  REMOVE_ALL_UNDERSCORES: 'removeAllUnderscores',
  TO_LOWERCASE: 'toLowerCase',
  TO_UPPERCASE: 'toUpperCase',
} as const;

export function formatString(input: string, conditions: string[]): string {
  // Define transformations
  const transformations: { [key: string]: (str: string) => string } = {
    getFirstLetterInUpperCase: (str) => str.charAt(0).toUpperCase(),
    firstLetterToUpperCase: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    removeAllUnderscores: (str) => str.replace(/_/g, ' '),
    toLowerCase: (str) => str.toLowerCase(),
    toUpperCase: (str) => str.toUpperCase(),
    reverseString: (str) => str.split('').reverse().join(''),
  };

  // Apply transformations based on conditions
  return conditions.reduce((result, condition) => {
    const transform = transformations[condition];
    if (transform) {
      return transform(result);
    }

    return result; // If no matching transformation, return unchanged
  }, input);
}
