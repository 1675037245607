<script setup lang="ts">
import { useRoute } from 'vue-router';

import { OrganizationRights } from '@/client';

import { haveUserAccess } from '@/composables/Flags.ts';
import { hasOrganizationRights } from '@/composables/Auth.ts';

import { organizationIds } from '@/utils/DataTestIds.ts';

const route = useRoute();
</script>

<template>
  <div class="menu">
    <q-btn
      v-if="haveUserAccess()"
      flat
      class="path"
      :data-test-id="organizationIds.organizationUserManagementTab"
      :to="`/organization/users`"
      :class="route.name === 'organization-users' ? 'active' : ''"
    >
      User Management
    </q-btn>
    <q-btn
      flat
      class="path"
      :to="`/organization/templates`"
      :class="
        route.name === 'organization-templates' || route.name === 'template-detail' ? 'active' : ''
      "
    >
      Frameworks
    </q-btn>
    <q-btn
      flat
      class="path"
      :to="`/organization/customization`"
      :class="route.name === 'organization-customization' ? 'active' : ''"
    >
      Customization
    </q-btn>
    <q-btn
      v-if="hasOrganizationRights(OrganizationRights.Configread)"
      flat
      class="path"
      :to="`/organization/settings`"
      :class="route.name === 'organization-settings' ? 'active' : ''"
    >
      Settings
    </q-btn>
  </div>
</template>

<style scoped lang="scss">
.menu {
  a {
    @include paragraph-02(400, $secondary-500);
    text-transform: none;
    padding: 4px;
    margin: 0 16px;
  }
  .active {
    @include paragraph-02(700, $secondary-600);
    position: relative;
  }
  .active::after {
    content: '';
    width: 100%;
    height: 3px;
    background: $secondary-600;
    position: absolute;
    bottom: -15px;
    right: 0;
  }
}
</style>
