<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps({
  activeTab: {
    type: String,
    required: true,
  },
  tabSize: {
    type: Object,
    default: () => {
      return {
        md: 5,
        lg: 5,
      };
    },
  },
  defaultSize: {
    type: Boolean,
    default: false,
  },
  defaultTab: {
    type: String,
    required: true,
  },
  noPanels: {
    type: Boolean,
  },
});

const tab = ref(props.defaultTab);

const emit = defineEmits(['changeTab']);

watch(
  () => props.activeTab,
  (newV) => {
    tab.value = newV;
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <q-card class="row">
    <q-tabs
      v-model="tab"
      dense
      class="text-grey"
      :class="[defaultSize ? '' : `col-md-${tabSize.md} col-lg-${tabSize.lg}`]"
      active-color="primary"
      indicator-color="primary"
      align="justify"
      narrow-indicator
      @update:model-value="(value: string) => emit('changeTab', value)"
    >
      <slot name="tabs" />
    </q-tabs>

    <q-separator v-if="!noPanels" />

    <slot name="action-row" />

    <q-tab-panels v-if="!noPanels" v-model="tab" class="col-12 panels__container" keep-alive>
      <slot name="panels" />
    </q-tab-panels>
  </q-card>
</template>

<style scoped lang="scss">
:deep(.q-tab--active) {
  .specific__content {
    background: $secondary-500 !important;
    color: $white !important;
  }
}
.q-card {
  background: transparent;
  box-shadow: unset;
  .panels__container {
    margin-top: 20px;
  }
  :deep(.q-tab-panels) {
    background: transparent;
  }

  :deep(.my-tab) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row !important;

    .q-tab__label {
      @include paragraph-02(400, $secondary-600);
      text-transform: none;
    }

    .q-tab__indicator {
      color: $secondary-600 !important;
    }
    .specific__content {
      margin-left: 5px;
      border-radius: 10px;
      background: $secondary-100;
      color: $secondary-800;
      padding: 1px 8px;
      letter-spacing: 1px;
    }
  }
}
</style>
