/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Rights = {
    Read: 'projects:read',
    Update: 'projects:update',
    Delete: 'projects:delete',
    UserRolesassign: 'projects:user_roles:assign',
    UserRolesdelete: 'projects:user_roles:delete',
    Dashboardread: 'projects:dashboard:read',
    Commentsread: 'projects:comments:read',
    Commentscreate: 'projects:comments:create',
    Logsread: 'projects:logs:read',
    AiSystemLifeCycleDashboardread: 'projects:ai_system_life_cycle_dashboard:read',
    Frameworkscreate: 'projects:frameworks:create',
    Frameworksread: 'projects:frameworks:read',
    Requirementsread: 'projects:requirements:read',
    EuAiActsettingsread: 'projects:eu_ai_act:settings:read',
    EuAiActsettingsupdate: 'projects:eu_ai_act:settings:update',
    Risksread: 'projects:risks:read',
    Riskscreate: 'projects:risks:create',
    Risksupdate: 'projects:risks:update',
    Risksassigneeupdate: 'projects:risks:assignee:update',
    Riskstreatmentread: 'projects:risks:treatment:read',
    Riskstreatmentcreate: 'projects:risks:treatment:create',
    Riskstreatmentupdate: 'projects:risks:treatment:update',
    Riskstreatmentstrategyread: 'projects:risks:treatment:strategy:read',
    Riskstreatmentstrategycreate: 'projects:risks:treatment:strategy:create',
    Riskstreatmentstrategyupdate: 'projects:risks:treatment:strategy:update',
    Riskstreatmentstrategydelete: 'projects:risks:treatment:strategy:delete',
    Riskscontrolsread: 'projects:risks:controls:read',
    Riskscontrolsdelete: 'projects:risks:controls:delete',
    Controlsrisksadd: 'projects:controls:risks:add',
    Controlsread: 'projects:controls:read',
    Controlsupdate: 'projects:controls:update',
    Controlsassigneeupdate: 'projects:controls:assignee:update',
    Controlsassessmentsread: 'projects:controls:assessments:read',
    Controlsassessmentscreate: 'projects:controls:assessments:create',
    Controlsassessmentsagentrun: 'projects:controls:assessments:agent:run',
    ControlsaiRepoMonitoringagentrun: 'projects:controls:ai_repo_monitoring:agent:run',
    Evidencesread: 'projects:evidences:read',
    Evidencesupdate: 'projects:evidences:update',
    Evidencesdelete: 'projects:evidences:delete',
    Evidencesdownload: 'projects:evidences:download',
    Evidencesupload: 'projects:evidences:upload',
    Evidencessuggestionsagentrun: 'projects:evidences:suggestions:agent:run',
    Usersread: 'projects:users:read',
    Reviewsread: 'projects:reviews:read',
    Reviewsrequest: 'projects:reviews:request',
    Reviewsupdate: 'projects:reviews:update',
    ReviewssetDecision: 'projects:reviews:set_decision',
    ReviewscancelPending: 'projects:reviews:cancel_pending',
    ReviewsforceUpdateStatus: 'projects:reviews:force_update_status',
    AssetCardscreate: 'projects:asset_cards:create',
    AssetCardsread: 'projects:asset_cards:read',
    AssetCardsupdate: 'projects:asset_cards:update',
    AssetCardsdownload: 'projects:asset_cards:download',
    AssetCardsassigneeupdate: 'projects:asset_cards:assignee:update',
    TestingLogscreate: 'projects:testing_logs:create',
    TestingSourcesread: 'projects:testing_sources:read',
    TestingSourcesmetricsread: 'projects:testing_sources:metrics:read',
    TestingSourcesmetricscreate: 'projects:testing_sources:metrics:create',
    TestingTestread: 'projects:testing_test:read',
    TestingTestcreate: 'projects:testing_test:create',
    TestingTestupdate: 'projects:testing_test:update',
    TestingTestdelete: 'projects:testing_test:delete',
    TestingTestresultsread: 'projects:testing_test:results:read',
    TestingTestresultsrun: 'projects:testing_test:results:run',
    TestingTestcontrolsread: 'projects:testing_test:controls:read',
    TestingTestcontrolsdelete: 'projects:testing_test:controls:delete',
    TestingTestcontrolscreate: 'projects:testing_test:controls:create',
    Questionnaireread: 'projects:questionnaire:read',
    Questionnaireupdate: 'projects:questionnaire:update',
    Questionsread: 'projects:questions:read',
    Questionsanswer: 'projects:questions:answer'
} as const;

export type Rights = typeof Rights[keyof typeof Rights];



