import type {
  AssetCardPage,
  AssignedRisks,
  CustomNotificationPage,
  CustomProjectPage,
  EvidencePage,
  PageControlList,
  PageDocumentOverview,
  PageRequirementAggregator,
  PageTestingTest,
  PageVendorDetails,
  RiskWithOverviewPage,
  TestingTestAndResultPage,
  TestingTestResultPage,
  UserOrgPage,
  UserProjectPage,
  OrganizationsMetricsPage,
  AssetCardTypes,
  Assignment,
  ControlStatusFilter,
  ProjectType,
  Roles,
  AssignedControls,
} from '@/client';

export type PaginationData =
  | AssetCardPage
  | AssignedControls
  | AssignedRisks
  | CustomNotificationPage
  | CustomProjectPage
  | EvidencePage
  | OrganizationsMetricsPage
  | PageControlList
  | PageDocumentOverview
  | PageRequirementAggregator
  | PageTestingTest
  | PageVendorDetails
  | RiskWithOverviewPage
  | TestingTestAndResultPage
  | TestingTestResultPage
  | UserOrgPage
  | UserProjectPage;

export interface TablePagination {
  descending?: boolean;
  page?: number;
  rowsPerPage?: number;
  sortBy?: string | null;
  filter?: string;
}

export interface Pagination {
  tags?: string[] | undefined;
  name?: string | null;
  type?: ProjectType | AssetCardTypes | undefined;
  description?: string;
  page: number | undefined;
  size: number | undefined;
  sortBy: string | null;
  descending: boolean | undefined;
  framework?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status?: any | null;
  associatedControls?: string[];
  associatedRisks?: string[];
  isAssignedToMe?: boolean;
  isAssignedToMeForReview?: boolean;
  startDate?: string | null;
  endDate?: string | null;
  file_type?: string | null;
  project_id?: string;
  roles?: Roles[] | undefined;
}

export interface SecondaryPagination {
  search: string | null;
  id: string | null;
  status: ControlStatusFilter | undefined;
  assignedAs: Assignment | undefined;
}

export const DEFAULT_PAGINATION_SIZE = 10;
export const SMALLER_PAGINATION_SIZE = 5;

export const DEFAULT_PAGINATION: Pagination = {
  page: 1,
  size: DEFAULT_PAGINATION_SIZE,
  sortBy: null,
  descending: false,
} as const;
