import { defineStore } from 'pinia';
import { ref } from 'vue';

import { AuthenticationApi, UsersApi } from '@/client';
import initApi from '@/client_heplers/utils.ts';
import { Configuration } from '../client/configuration.ts';

import { useConfigStore } from './ConfigStore.ts';

import { handleApiError } from '@/composables/ApiError.ts';

import type {
  AuthenticationApiApiTokenApiAuthTokenPostRequest,
  AuthenticationApiCreateApiTokenApiAuthApiPostRequest,
  AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest,
  AuthenticationApiRegisterApiAuthRegisterPostRequest,
  ModulosPlatformBackendSchemasAuthorizationsToken,
  ModulosPlatformBackendSchemasTokensToken,
  UsersApiPatchUsersMeApiUsersMePatchRequest,
} from '@/client';
import type { AxiosError, AxiosResponse } from 'axios';
import type { OrganizationUser } from '../client/index.ts';

const baseUrl = `${import.meta.env.VITE_API_URL}`;
const token = localStorage.getItem('access_token');
const config = new Configuration();
config.basePath = baseUrl;
config.accessToken = token ? token : '';

const authApi = new AuthenticationApi(config);
const usersApi = new UsersApi(config);

export const useAuthStore = defineStore('auth', () => {
  const user = ref<null | OrganizationUser>(null);
  const allTokens = ref<null | ModulosPlatformBackendSchemasTokensToken[]>(null);
  const tokenData = ref<null | ModulosPlatformBackendSchemasAuthorizationsToken>(null);

  const getTokenFromAuthCodeAndUser = async (
    params: AuthenticationApiApiTokenApiAuthTokenPostRequest,
  ) => {
    try {
      const response: AxiosResponse = await authApi.apiTokenApiAuthTokenPost(params);

      if (response && response.status === 201) {
        localStorage.setItem('access_token', response.data.access_token);
        config.accessToken = response.data.access_token;

        localStorage.setItem('refresh_token', response.data.refresh_token);

        await getMeAndOrgConfig();
      }
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const registerTokenFromAuthCode = async (
    params: AuthenticationApiRegisterApiAuthRegisterPostRequest,
  ) => {
    try {
      const response: AxiosResponse = await authApi.registerApiAuthRegisterPost(params);

      if (response && response.status === 201) {
        localStorage.setItem('access_token', response.data.access_token);
        config.accessToken = response.data.access_token;

        localStorage.setItem('refresh_token', response.data.refresh_token);

        await getMeAndOrgConfig();
      }
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getMeAndOrgConfig = async () => {
    const configStore = useConfigStore();

    try {
      const response = await usersApi.getUsersMeApiUsersMeGet();

      if (!response) return;

      user.value = response.data;
      localStorage.setItem('user', JSON.stringify(user.value));

      if (user.value && user.value.organization) {
        await configStore.getOrganizationConfig();
      }
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getTokens = async () => {
    try {
      const response: AxiosResponse = await authApi.getApiTokensApiAuthApiGet();
      allTokens.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateTermsAccepted = async (params: UsersApiPatchUsersMeApiUsersMePatchRequest) => {
    try {
      await usersApi.patchUsersMeApiUsersMePatch(params);

      await getMeAndOrgConfig();
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const createToken = async (params: AuthenticationApiCreateApiTokenApiAuthApiPostRequest) => {
    try {
      const response: AxiosResponse = await authApi.createApiTokenApiAuthApiPost(params);

      tokenData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const deleteToken = async (params: AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest) => {
    try {
      await authApi.deleteApiTokenApiAuthApiDelete(params);

      await getTokens();
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const getAccessTokenFromRefreshToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    localStorage.removeItem('access_token');
    if (!refreshToken) return;

    try {
      const response: AxiosResponse = await authApi.refreshTokenApiAuthRefreshPost({
        grantType: 'refresh_token',
        refreshToken,
      });

      if (response && response.data && response.data.access_token) {
        localStorage.setItem('access_token', response.data.access_token);
        config.accessToken = response.data.access_token;

        return response.data.access_token;
      } else {
        console.error('Invalid response format');
      }
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const userLastAccess = async () => {
    try {
      const usersApi = await initApi(UsersApi);
      await usersApi?.patchUsersMeLastAccessApiUsersMeLastAccessPatch();
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    getMeAndOrgConfig,
    getTokenFromAuthCodeAndUser,
    registerTokenFromAuthCode,
    user,
    allTokens,
    getTokens,
    createToken,
    tokenData,
    deleteToken,
    getAccessTokenFromRefreshToken,
    userLastAccess,
    updateTermsAccepted,
  };
});
