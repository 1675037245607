import { ref } from 'vue';

import { useUsersStore } from '@/stores/UsersStore.ts';
import { useOrganizationStore } from '@/stores/OrganizationStore.ts';

import type { ResponseGetUserApiUsersUserIdGet, UserInOrgList } from '@/client';
import { captureError } from './Sentry.ts';

interface OrganizationUsersMap {
  [userId: string]: UserInOrgList | ResponseGetUserApiUsersUserIdGet;
}

export const usersHashMap = ref<OrganizationUsersMap>({});

export const createUsersHashMap = () => {
  const organizationStore = useOrganizationStore();

  const usersData = organizationStore.usersData;

  if (!usersData) return;

  usersHashMap.value = usersData.items.reduce((acc, user) => {
    acc[user.id] = user;
    return acc;
  }, {} as OrganizationUsersMap);
};

export const getUser = async (userId: string) => {
  const user = usersHashMap.value[userId];

  if (user) return user;

  const usersStore = useUsersStore();

  try {
    const userResponse = await usersStore.getUser({ userId });

    if (!userResponse) return undefined;

    usersHashMap.value[userId] = userResponse;

    return userResponse;
  } catch (error) {
    captureError(error, {
      message: 'Composable: Users, Function: getUser',
    });
  }
};
